import {
  ANNOUNCEMENT_WAS_DELETE,
  RESET_ANNOUNCEMENT_WAS_DELETE,
  GET_ALL_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_BY_ID,
  SET_VIEWED_ANNOUNCEMENT,
  SET_VIEWED_ALL_ANNOUNCEMENT,
  CLEAR_STATE,
  GET_ANNOUNCEMENT_STUDY_GROUPS,
  SET_ANNOUNCEMENT_FETCH_IN_PROGRESS,
  SET_ANNOUNCEMENT_TAB,
} from './announcementTypes'

const initialState = {
  allAnnouncement: {},
  announcement: {},
  announcementWasDeleted: false,
  studyGroups: null,
  fetchInProgress: false,
  currentTab: 'shared', // 'my' | 'shared
}

const announcementReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ANNOUNCEMENT:
      return { ...prevState, allAnnouncement: { ...action.data }, fetchInProgress: false }
    case GET_ANNOUNCEMENT_BY_ID:
      return {
        ...prevState,
        announcement: { ...prevState.announcement, [action.id]: action.data.data },
      }
    case ANNOUNCEMENT_WAS_DELETE:
      return { ...prevState, announcementWasDeleted: true }
    case RESET_ANNOUNCEMENT_WAS_DELETE:
      return { ...prevState, announcementWasDeleted: false }
    case SET_VIEWED_ANNOUNCEMENT:
      return {
        ...prevState,
        allAnnouncement: {
          ...prevState.allAnnouncement,
          data: prevState.allAnnouncement.data.map((element) =>
            element.id === action.data ? { ...element, viewed: true } : element,
          ),
        },
      }
    case SET_VIEWED_ALL_ANNOUNCEMENT:
      return {
        ...prevState,
        allAnnouncement: {
          ...prevState.allAnnouncement,
          data: prevState.allAnnouncement.data.map((element) => ({ ...element, viewed: true })),
        },
      }
    case GET_ANNOUNCEMENT_STUDY_GROUPS:
      return {
        ...prevState,
        studyGroups: action.data,
      }
    case SET_ANNOUNCEMENT_FETCH_IN_PROGRESS:
      return { ...prevState, fetchInProgress: action.data }
    case SET_ANNOUNCEMENT_TAB:
      return { ...prevState, currentTab: action.data }
    case CLEAR_STATE:
      return initialState
    default:
      return prevState
  }
}
export default announcementReducer
