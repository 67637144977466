export const GET_ALL_SCHEDULE = 'GET_ALL_SCHEDULE'
export const GET_BY_ID_SCHEDULE = 'GET_BY_ID_SCHEDULE'
export const CLEAR_LESSON = 'CLEAR_LESSON'
export const GET_LESSON_DISCIPLINES = 'GET_LESSON_DISCIPLINES'
export const GET_LESSON_STUDY_GROUPS = 'GET_LESSON_STUDY_GROUPS'
export const RESET_LESSON_STUDY_GROUPS = 'RESET_LESSON_STUDY_GROUPS'
export const GET_LESSON_TEACHER_USERS = 'GET_LESSON_TEACHER_USERS'
export const GET_LESSON_TEACHERS = 'GET_LESSON_TEACHERS'

export const GET_ALL_STUDY_GROUP_SCHEDULE = 'GET_ALL_STUDY_GROUP_SCHEDULE'
export const GET_BY_ID_STUDY_GROUP_SCHEDULE = 'GET_BY_ID_STUDY_GROUP_SCHEDULE'
export const CREATE_STUDY_GROUP_SCHEDULE = 'CREATE_STUDY_GROUP_SCHEDULE'
export const UPDATE_STUDY_GROUP_SCHEDULE = 'UPDATE_STUDY_GROUP_SCHEDULE'
export const REMOVE_STUDY_GROUP_SCHEDULE = 'REMOVE_STUDY_GROUP_SCHEDULE'
export const RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED =
  'RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED'

export const GET_ALL_SCHEDULE_ACADEMIC_PLANS = 'GET_ALL_SCHEDULE_ACADEMIC_PLANS'
export const GET_BY_ID_SCHEDULE_ACADEMIC_PLANS = 'GET_BY_ID_SCHEDULE_ACADEMIC_PLANS'
export const CREATE_SCHEDULE_ACADEMIC_PLANS = 'CREATE_SCHEDULE_ACADEMIC_PLANS'
export const UPDATE_SCHEDULE_ACADEMIC_PLANS = 'UPDATE_SCHEDULE_ACADEMIC_PLANS'
export const RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED =
  'RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED'

export const GET_ONLINE_PLATFORM_FOR_LESSON_CREATE = 'GET_ONLINE_PLATFORM_FOR_LESSON_CREATE'

export const CREATE_LESSON = 'CREATE_LESSON'
export const UPDATE_LESSON = 'UPDATE_LESSON'

export const RESET_CREATED_LESSON_ID = 'RESET_CREATED_LESSON_ID'

export const CLEAR_LESSONS_STORE = 'CLEAR_LESSONS_STORE'

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const RESET_DELETE_SCHEDULE = 'RESET_DELETE_SCHEDULE'

export const GET_CURRENT_SCHEDULE_WORK = 'GET_CURRENT_SCHEDULE_WORK'
export const RESET_CURRENT_SCHEDULE_WORK = 'RESET_CURRENT_SCHEDULE_WORK'
export const GET_SCHEDULE_WORK_BY_ID = 'GET_SCHEDULE_WORK_BY_ID'
export const RESET_SCHEDULE_WORK = 'RESET_SCHEDULE_WORK'
export const GET_ALL_SCHEDULE_WORK = 'GET_ALL_SCHEDULE_WORK'

export const GET_SCHEDULE_STATUS = 'GET_SCHEDULE_STATUS'

export const RESET_CREATE_SCHEDULE_WAS_CREATED = 'RESET_CREATE_SCHEDULE_WAS_CREATED'
export const RESET_CREATE_SCHEDULE_WAS_UPDATED = 'RESET_CREATE_SCHEDULE_WAS_UPDATED'

export const GET_ALL_DEPARTMENT = 'GET_ALL_DEPARTMENT'
export const GET_DEPARTMENT_BY_ID = 'GET_DEPARTMENT_BY_ID'
export const CREATE_DEPARTMENT_BIND = 'CREATE_DEPARTMENT_BIND'
export const UPDATE_DEPARTMENT_BIND = 'UPDATE_DEPARTMENT_BIND'
export const DELETE_DEPARTMENT_BIND = 'DELETE_DEPARTMENT_BIND'

export const SET_SCHEDULE_DATE = 'SET_SCHEDULE_DATE'

export const GET_PRINT_MODAL_DATA = 'GET_PRINT_MODAL_DATA'
export const SET_PRINT_MODAL_DATA = 'SET_PRINT_MODAL_DATA'
export const RESET_PRINT_MODAL_DATA = 'RESET_PRINT_MODAL_DATA'

export const SET_PRINT_DATA = 'SET_PRINT_DATA'
export const RESET_PRINT_DATA = 'RESET_PRINT_DATA'

export const SET_FIRST_UNREAD_LESSON = 'SET_FIRST_UNREAD_LESSON'
export const RESET_FIRST_UNREAD_LESSON = 'RESET_FIRST_UNREAD_LESSON'

export const GET_LESSON_MEMBERS_COUNT = 'GET_LESSON_MEMBERS_COUNT'
export const CLEAR_LESSON_MEMBERS_COUNT = 'CLEAR_LESSON_MEMBERS_COUNT'
