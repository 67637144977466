export const GET_ALL_APPEALS = 'GET_ALL_APPEALS'
export const GET_APPEALS_BY_ID = 'GET_APPEALS_BY_ID'
export const EDIT_APPEALS_BY_ID = 'EDIT_APPEALS_BY_ID'
export const CREATE_APPEALS = 'CREATE_APPEALS'
export const GET_APPEALS_FIELDS = 'GET_APPEALS_FIELDS'
export const GET_APPEALS_THEMES = 'GET_APPEALS_THEMES'
export const RESET_APPEALS_STATE = 'RESET_APPEALS_STATE'
export const SET_APPEAL_FETCH_IN_PROGRESS = 'SET_APPEAL_FETCH_IN_PROGRESS'
export const GET_DEFAULT_RESPONDENT = 'GET_DEFAULT_RESPONDENT'
export const DELETE_APPEAL_BY_ID = 'DELETE_APPEAL_BY_ID'
