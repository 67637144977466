import common from './common.json'
import _nav from './_nav.json'
import page404 from './views/pages/page404/index.json'
import pageIsDeveloping from './views/pages/pageIsDeveloping/index.json'
import defaultValidationErrors from './defaultValidationErrors.json'
import mainPage from './views/mgu/home/mainPage.json'
import dashboard from './views/mgu/dashboard/dashboard.json'
import announcementList from './views/mgu/announcement/list.json'
import announcementEdit from './views/mgu/announcement/edit.json'
import studentAdvertList from './views/mgu/announcement/studentList.json'
import appealList from './views/mgu/appeals/list.json'
import appealAdd from './views/mgu/appeals/add.json'
import appealDetail from './views/mgu/appeals/detail.json'
import appealFilter from './views/mgu/appeals/filter.json'
import referenceOrderList from './views/mgu/reference_order/list.json'
import referenceOrderAdd from './views/mgu/reference_order/add.json'
import referenceOrderDetail from './views/mgu/reference_order/detail.json'
import referenceOrderFilter from './views/mgu/reference_order/filter.json'
import infoList from './views/mgu/info/list.json'
import infoAdd from './views/mgu/info/add.json'
import infoEdit from './views/mgu/info/edit.json'
import userList from './views/mgu/users/list.json'
import userListTable from './views/mgu/users/listTable.json'
import userListFilter from './views/mgu/users/listFilter.json'
import userDetail from './views/mgu/users/detail.json'
import userCreate from './views/mgu/users/create.json'
import userDetailUser from './views/mgu/users/detailUser.json'
import associationGroupDetail from './views/mgu/users/associationGroupDetail.json'
import associationGroupHistory from './views/mgu/users/associationGroupHistory.json'
import userDetailProfiles from './views/mgu/users/detailProfiles.json'
import userDetailAgrees from './views/mgu/users/detailAgrees.json'
import userDetailRights from './views/mgu/users/detailRights.json'
import userDetailNotifications from './views/mgu/users/detailNotifications.json'
import userNotificationSettings from './views/mgu/users/notificationSettings.json'
import bindUsers from './views/mgu/users/bindUsers.json'
import bindUsersModal from './views/mgu/users/bindUsersModal.json'
import selectGroupModal from './views/mgu/users/selectGroupModal.json'
import handbookList from './views/mgu/handbooks/list.json'
import handbookFilter from './views/mgu/handbooks/filter.json'
import handbookEdit from './views/mgu/handbooks/edit.json'
import lessonTypesHandbookEdit from './views/mgu/handbooks/lessonTypesEdit.json'
import classroomTypesHandbookEdit from './views/mgu/handbooks/classroomTypesEdit.json'
import classroomsHandbookList from './views/mgu/handbooks/classroomsList.json'
import classroomsHandbookEdit from './views/mgu/handbooks/classroomsEdit.json'
import disciplineHandbookList from './views/mgu/handbooks/discioplineList.json'
import disciplineHandbookEdit from './views/mgu/handbooks/discioplineEdit.json'
import tooltipsHandbookEdit from './views/mgu/handbooks/tooltipsEdit.json'
import preparationTypesHandbookEdit from './views/mgu/handbooks/preparationTypesEdit.json'
import appHeaderRightMenu from './components/header/appHeaderRightMenu.json'
import appHeaderDropdown from './components/header/appHeaderDropdown.json'
import appDropzone from './components/ui/appDropzone.json'
import appTextEditor from './components/ui/appTextEditor.json'
import appSortMobile from './components/ui/appSortMobile.json'
import appCookieAlert from './components/ui/appCookieAlert.json'
import appDateTimePicker from './components/ui/appDateTimePicker.json'
import modalsDefault from './components/modals/modalsDefault.json'
import appAuthProvider from './components/providers/appAuthProvider.json'
import profile from './views/mgu/profile/index.json'
import profileAdditionalContacts from './views/mgu/profile/additionalContacts.json'
import profileChangePassword from './views/mgu/profile/changePassword.json'
import global from './global.json'
import homeworkTable from './views/mgu/homework/homeworkTable.json'
import homeworkFilter from './views/mgu/homework/homeworkFilter.json'
import homeworkDetails from './views/mgu/homework/homeworkDetails.json'
import settingList from './views/mgu/setting/table.json'
import settingEdit from './views/mgu/setting/edit.json'
import settingLessonEditingProhibitions from './views/mgu/setting/lesson_editing_prohibitions.json'
import scheduleLessons from './views/mgu/schedule/lessons.json'
import scheduleWork from './views/mgu/schedule/working.json'
import scheduleFilter from './views/mgu/schedule/filter.json'
import scheduleTable from './views/mgu/schedule/table.json'
import scheduleGrid from './views/mgu/schedule/grid.json'
import scheduleDetail from './views/mgu/schedule/lessonDetail.json'
import scheduleStudGroup from './views/mgu/schedule/studyGroup.json'
import scheduleAcademicPlans from './views/mgu/schedule/academicPlans.json'
import groupsInDepartment from './views/mgu/schedule/groupsInDepartment.json'
import appSelectStudent from './components/ui/appSelectStudent.json'
import appFooter from './components/layouts/appFooter.json'
import progress from './views/mgu/progress/progress.json'
import email from './views/mgu/email/email.json'
import homeTasks from './views/mgu/homeTask/homeTask.json'
import appSelectUser from './components/ui/appSelect.json'
import appSelectCoworker from './components/ui/appSelectCoworker.json'
import recipients from './views/mgu/setting/recipients.json'
import setPassword from './views/mgu/systems/setPassword.json'
import recoverPassword from './views/mgu/systems/recoverPassword.json'
import errors from './errors.json'
import academicGroups from './views/mgu/academic_groups/list.json'
import stipend from './views/mgu/stipend/stipend.json'
import headmans from './views/mgu/headmans/headmans.json'
import headmansManagement from './views/mgu/setting/headmansManagement.json'
import electronicJournal from './views/mgu/electronicJournal/electronicJournal.json'
import electronicJournalFilter from './views/mgu/electronicJournal/electronicJournalFilter.json'
import electronicJournalTable from './views/mgu/electronicJournal/electronicJournalTable.json'
import electronicJournalDetail from './views/mgu/electronicJournal/electronicJournalDetail.json'
import electronicJournalGrid from './views/mgu/electronicJournal/electronicJournalGrid.json'
import absenceReasons from './views/mgu/absenceReasons/absenceReasons.json'
import currentProgress from './views/mgu/currentProgress/currentProgress.json'
import currentProgressFilter from './views/mgu/currentProgress/currentProgressFilter.json'
import currentProgressTable from './views/mgu/currentProgress/currentProgressTable.json'
import currentProgressLegend from './views/mgu/currentProgress/currentProgressLegend.json'
import paySlips from './views/mgu/paySlips/paySlips.json'
import recipientsReasonsFailure from './views/mgu/recipientsReasonsFailure/recipientsReasonsFailure.json'
import teachersNotUsersHandbookEdit from './views/mgu/handbooks/teachersNotUsersHandbookEdit.json'
import contractPayment from './views/mgu/contractPayment/contractPayment.json'
import notificationSettings from './views/mgu/notificationSettings/notificationSettings.json'
import orders from './views/mgu/orders/orders.json'
import howToGetAccess from './views/mgu/setting/howToGetAccess.json'
import usersDownloadReport from './views/mgu/users/usersDownloadReport.json'
import userInvite from './views/mgu/users/userInvite.json'
import statements from './views/mgu/statements/statements.json'
import statementsDownloadReport from './views/mgu/statements/statementsDownloadReport.json'
import statementsAbsenceReasonModal from './views/mgu/statements/statementsAbsenceReasonModal.json'

const lang = {
  ...common,
  ..._nav,
  ...page404,
  ...pageIsDeveloping,
  ...defaultValidationErrors,
  ...mainPage,
  ...dashboard,
  ...announcementList,
  ...announcementEdit,
  ...studentAdvertList,
  ...appealList,
  ...appealAdd,
  ...appealDetail,
  ...appealFilter,
  ...referenceOrderList,
  ...referenceOrderAdd,
  ...referenceOrderDetail,
  ...referenceOrderFilter,
  ...infoList,
  ...infoAdd,
  ...infoEdit,
  ...userList,
  ...userListTable,
  ...userListFilter,
  ...userDetail,
  ...userCreate,
  ...userDetailUser,
  ...associationGroupDetail,
  ...associationGroupHistory,
  ...userDetailProfiles,
  ...userDetailAgrees,
  ...userDetailRights,
  ...userDetailNotifications,
  ...userNotificationSettings,
  ...bindUsers,
  ...bindUsersModal,
  ...selectGroupModal,
  ...handbookList,
  ...handbookFilter,
  ...handbookEdit,
  ...lessonTypesHandbookEdit,
  ...classroomTypesHandbookEdit,
  ...classroomsHandbookList,
  ...classroomsHandbookEdit,
  ...tooltipsHandbookEdit,
  ...preparationTypesHandbookEdit,
  ...disciplineHandbookList,
  ...disciplineHandbookEdit,
  ...appHeaderRightMenu,
  ...appHeaderDropdown,
  ...appDropzone,
  ...appTextEditor,
  ...appSortMobile,
  ...appCookieAlert,
  ...appDateTimePicker,
  ...modalsDefault,
  ...appAuthProvider,
  ...profile,
  ...profileAdditionalContacts,
  ...profileChangePassword,
  ...global,
  ...homeworkTable,
  ...homeworkFilter,
  ...homeworkDetails,
  ...settingList,
  ...settingEdit,
  ...settingLessonEditingProhibitions,
  ...scheduleLessons,
  ...scheduleWork,
  ...scheduleFilter,
  ...scheduleTable,
  ...scheduleGrid,
  ...scheduleStudGroup,
  ...scheduleAcademicPlans,
  ...scheduleDetail,
  ...appSelectStudent,
  ...appFooter,
  ...groupsInDepartment,
  ...progress,
  ...email,
  ...homeTasks,
  ...appSelectUser,
  ...appSelectCoworker,
  ...recipients,
  ...setPassword,
  ...recoverPassword,
  ...errors,
  ...academicGroups,
  ...stipend,
  ...headmans,
  ...headmansManagement,
  ...electronicJournal,
  ...electronicJournalFilter,
  ...electronicJournalTable,
  ...electronicJournalDetail,
  ...electronicJournalGrid,
  ...absenceReasons,
  ...currentProgress,
  ...currentProgressFilter,
  ...currentProgressTable,
  ...currentProgressLegend,
  ...paySlips,
  ...recipientsReasonsFailure,
  ...teachersNotUsersHandbookEdit,
  ...contractPayment,
  ...notificationSettings,
  ...orders,
  ...howToGetAccess,
  ...usersDownloadReport,
  ...userInvite,
  ...statements,
  ...statementsDownloadReport,
  ...statementsAbsenceReasonModal,
}

export default lang
