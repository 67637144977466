import {
  CLEAR_STATEMENTS,
  GET_STATEMENTS,
  SET_DOWLOAD_STATEMENT_IN_PROGRESS,
  SET_DOWNLOAD_STATEMENT_PROCESS_ID,
} from './statementsTypes'

const initialState = {
  satements: {
    summary_group: {},
    summary_course: {},
    exam: {},
    retake: {},
    commission: {},
  },
  downloadInProgress: false,
  processId: null,
}

/**
 * Хранилище для ведомостей
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const statementsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_STATEMENTS:
      return {
        ...prevState,
        statements: {
          ...initialState.satements,
          [action.data.type]: action.data.data,
        },
      }
    case SET_DOWLOAD_STATEMENT_IN_PROGRESS:
      return {
        ...prevState,
        downloadInProgress: action.data,
      }
    case SET_DOWNLOAD_STATEMENT_PROCESS_ID:
      return {
        ...prevState,
        processId: action.data,
      }
    case CLEAR_STATEMENTS:
      return initialState
    default:
      return prevState
  }
}

export default statementsReducer
