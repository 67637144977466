import {
  CREATE_DEPARTMENT_BIND,
  CREATE_LESSON,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_ACADEMIC_PLANS,
  CREATE_STUDY_GROUP_SCHEDULE,
  GET_ALL_DEPARTMENT,
  GET_ALL_SCHEDULE,
  GET_ALL_SCHEDULE_ACADEMIC_PLANS,
  GET_ALL_SCHEDULE_WORK,
  GET_ALL_STUDY_GROUP_SCHEDULE,
  GET_BY_ID_SCHEDULE,
  GET_BY_ID_SCHEDULE_ACADEMIC_PLANS,
  GET_BY_ID_STUDY_GROUP_SCHEDULE,
  GET_LESSON_DISCIPLINES,
  GET_LESSON_STUDY_GROUPS,
  GET_LESSON_TEACHER_USERS,
  GET_LESSON_TEACHERS,
  GET_DEPARTMENT_BY_ID,
  GET_ONLINE_PLATFORM_FOR_LESSON_CREATE,
  REMOVE_STUDY_GROUP_SCHEDULE,
  RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED,
  RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED,
  UPDATE_DEPARTMENT_BIND,
  UPDATE_LESSON,
  UPDATE_SCHEDULE_ACADEMIC_PLANS,
  UPDATE_STUDY_GROUP_SCHEDULE,
  DELETE_DEPARTMENT_BIND,
  GET_SCHEDULE_STATUS,
  GET_CURRENT_SCHEDULE_WORK,
  UPDATE_SCHEDULE,
  GET_SCHEDULE_WORK_BY_ID,
  RESET_CREATE_SCHEDULE_WAS_CREATED,
  RESET_CREATE_SCHEDULE_WAS_UPDATED,
  RESET_CREATE_SCHEDULE_WAS_CREATED_OR_UPDATED,
  SET_SCHEDULE_DATE,
  RESET_LESSON_STUDY_GROUPS,
  GET_PRINT_MODAL_DATA,
  RESET_PRINT_MODAL_DATA,
  SET_PRINT_MODAL_DATA,
  SET_PRINT_DATA,
  RESET_PRINT_DATA,
  CLEAR_LESSONS_STORE,
  CLEAR_LESSON,
  DELETE_SCHEDULE,
  RESET_DELETE_SCHEDULE,
  RESET_CREATED_LESSON_ID,
  SET_FIRST_UNREAD_LESSON,
  RESET_FIRST_UNREAD_LESSON,
  RESET_CURRENT_SCHEDULE_WORK,
  RESET_SCHEDULE_WORK,
  GET_LESSON_MEMBERS_COUNT,
  CLEAR_LESSON_MEMBERS_COUNT,
} from './scheduleTypes'
import { setError } from '../errorReducer'
import api from '../../services/api'
import { RESET_CREATED_ROOM } from '../onlineRoom/onlineRoomTypes'

export const lessonUrl = '/lessons'
export const lessonCheckUrl = '/lessons/check'
export const getAllHomeScheduleUrl = '/schedule'
export const getAllScheduleStudyGroupUrl = '/study_groups'
export const getAllScheduleAcademicPlansUrl = '/academic_plans'
export const getOnlinePlatformUrl = '/online_platforms'
export const getPublicLessonsUrl = '/public_content/lessons'
export const getPublicCurrentScheduleUrl = '/public_content/schedules/current'
export const getPublicScheduleUrl = '/public_content/lessons'
export const schedulesUrl = '/schedules'
export const currentSchedulesUrl = '/schedules/current'
export const schedulesStatusUrl = '/schedule_statuses'
export const departmentUrl = '/department_groups'

export const getLessonMembersCount = (params, options = {}) => {
  return async (dispatch) => {
    return api
      .get(getAllScheduleStudyGroupUrl + '/members_count', { params: { ...params }, ...options })
      .then((res) =>
        dispatch({
          type: GET_LESSON_MEMBERS_COUNT,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const clearLessonMembersCount = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LESSON_MEMBERS_COUNT,
    })
  }
}

export const getAllScheduleStudyGroup = (params, options = {}) => {
  return async (dispatch) => {
    api
      .get(getAllScheduleStudyGroupUrl, { params: { ...params }, ...options })
      .then((res) =>
        dispatch({
          type: GET_ALL_STUDY_GROUP_SCHEDULE,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getByIDScheduleStudyGroup = (params, abortController) => {
  return async (dispatch) => {
    api
      .get(getAllScheduleStudyGroupUrl + '/' + params.id, {
        params: { ...params },
        signal: abortController.signal,
      })
      .then((res) =>
        dispatch({
          type: GET_BY_ID_STUDY_GROUP_SCHEDULE,
          data: res,
        }),
      )
      .catch((err) => {
        if (err.message !== 'canceled') dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const createStudyGroup = (body) => {
  return async (dispatch) => {
    api
      .post(getAllScheduleStudyGroupUrl, body)
      .then((res) => {
        dispatch({
          type: CREATE_STUDY_GROUP_SCHEDULE,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateStudyGroup = (body) => {
  return async (dispatch) => {
    api
      .put(getAllScheduleStudyGroupUrl + '/' + body.id, body)
      .then((res) => {
        dispatch({
          type: UPDATE_STUDY_GROUP_SCHEDULE,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const removeStudyGroup = (body) => {
  return async (dispatch) => {
    return api
      .delete(getAllScheduleStudyGroupUrl + '/' + body.id, body)
      .then((res) => {
        return dispatch({
          type: REMOVE_STUDY_GROUP_SCHEDULE,
          data: res,
        })
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getAllScheduleAcademicPlans = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(getAllScheduleAcademicPlansUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_ALL_SCHEDULE_ACADEMIC_PLANS,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}
export const getByIDScheduleAcademicPlans = (params) => {
  return async (dispatch) => {
    api
      .get(getAllScheduleAcademicPlansUrl + '/' + params.id)
      .then((res) =>
        dispatch({
          type: GET_BY_ID_SCHEDULE_ACADEMIC_PLANS,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const createScheduleAcademicPlans = (body) => {
  return async (dispatch) => {
    api
      .post(getAllScheduleAcademicPlansUrl, body)
      .then((res) => {
        dispatch({
          type: CREATE_SCHEDULE_ACADEMIC_PLANS,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateScheduleAcademicPlans = (id, body) => {
  return async (dispatch) => {
    api
      .put(getAllScheduleAcademicPlansUrl + '/' + id, body)
      .then((res) => {
        dispatch({
          type: UPDATE_SCHEDULE_ACADEMIC_PLANS,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getOnlinePlatform = () => {
  return async (dispatch) => {
    api
      .get(getOnlinePlatformUrl)
      .then((res) =>
        dispatch({
          type: GET_ONLINE_PLATFORM_FOR_LESSON_CREATE,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getAlLLessons = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(lessonUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_ALL_SCHEDULE,
          data: res,
        }),
      )
      .catch((err) => {
        if (err?.code === 'ERR_CANCELED') {
        } else {
          dispatch(setError(err.response?.status, err.message, err))
        }
      })
  }
}

export const getLessonById = (params = {}) => {
  return async (dispatch) => {
    api
      .get(lessonUrl + '/' + params.id, { params: { ...params.params } })
      .then((res) =>
        dispatch({
          type: GET_BY_ID_SCHEDULE,
          data: res,
        }),
      )
      .catch((err) => {
        if (err?.code === 'ERR_CANCELED') {
        } else {
          dispatch(setError(err.response?.status, err.message, err))
        }
      })
  }
}

export const clearLesson = (id) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LESSON,
      data: id,
    })
  }
}

export const getLessonDisciplines = (params = {}, config = {}) => {
  return async (dispatch) =>
    api
      .get(`${lessonUrl}/disciplines`, { ...config, params: { ...params } })
      .then((response) =>
        dispatch({
          type: GET_LESSON_DISCIPLINES,
          data: response.data.result.data,
        }),
      )
      .catch((err) => {
        //dispatch(setError(err.response?.status, err.message, err))
      })
}

export const getLessonStudyGroups = (params = {}, config = {}) => {
  return async (dispatch) =>
    api
      .get(`${lessonUrl}/study_groups`, { ...config, params: { ...params } })
      .then((response) =>
        dispatch({
          type: GET_LESSON_STUDY_GROUPS,
          data: response.data.result.data,
        }),
      )
      .catch((err) => {
        //dispatch(setError(err.response?.status, err.message, err))
      })
}

export const getLessonTeacherUsers = (params = {}, config = {}) => {
  return async (dispatch) =>
    api
      .get(`${lessonUrl}/teacher_users`, { ...config, params: { ...params } })
      .then((response) =>
        dispatch({
          type: GET_LESSON_TEACHER_USERS,
          data: response.data.result.data,
        }),
      )
      .catch((err) => {
        //dispatch(setError(err.response?.status, err.message, err))
      })
}

export const getLessonTeachers = (params = {}, config = {}) => {
  return async (dispatch) =>
    api
      .get(`${lessonUrl}/teachers`, { ...config, params: { ...params } })
      .then((response) =>
        dispatch({
          type: GET_LESSON_TEACHERS,
          data: response.data.result.data,
        }),
      )
      .catch((err) => {
        //dispatch(setError(err.response?.status, err.message, err))
      })
}

export const beforeCreateLesson = (body) => {
  return async (dispatch) => {
    return api
      .post(lessonCheckUrl, body)
      .then((res) => res.data.result)
      .catch((err) => {})
  }
}

export const beforeUpdateLesson = (body) => {
  return async (dispatch) => {
    return api
      .post(lessonCheckUrl + '/' + body.id, body)
      .then((res) => res.data.result)
      .catch((err) => {})
  }
}

export const createLesson = (body) => {
  return async (dispatch) => {
    return api
      .post(lessonUrl, body)
      .then((res) => {
        if (res.data.result.error.length > 0) {
          throw new Error(JSON.stringify(res))
        }
        dispatch({
          type: CREATE_LESSON,
          data: res,
        })
      })
      .catch((err) => {
        if ('response' in err) {
          dispatch(setError(err.response.status, err.message, err))
        } else {
          const _err = JSON.parse(err.message)
          dispatch(setError(_err.status, _err.data.result.error, _err))
        }
        return Promise.reject(err)
      })
  }
}

export const updateLesson = (body) => {
  return async (dispatch) => {
    return api
      .put(lessonUrl + '/' + body.id, body)
      .then((res) => {
        if (res.data.result.error.length > 0) {
          throw new Error(JSON.stringify(res))
        }
        dispatch({
          type: UPDATE_LESSON,
          data: res,
        })
      })
      .catch((err) => {
        if ('response' in err) {
          dispatch(setError(err.response.status, err.message, err))
        } else {
          const _err = JSON.parse(err.message)
          dispatch(setError(_err.status, _err.data.result.error, _err))
        }
        throw err
      })
  }
}

export const deleteLesson = (id, body = {}, config = {}) => {
  return async (dispatch) => {
    return api
      .delete(lessonUrl + '/' + id, { data: body, ...config })
      .then((res) => res)
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getPublicSchedule = (filter, config = {}) => {
  return async (dispatch) => {
    return api
      .get(getPublicLessonsUrl, {
        ...config,
        params: filter,
      })
      .then((response) => response)
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}
export const getPublicCurrentSchedule = (filter = {}, config = {}) => {
  return async (dispatch) => {
    return api
      .get(getPublicCurrentScheduleUrl, {
        ...config,
        params: filter,
      })
      .then((response) => response)
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const getScheduleStatus = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(schedulesStatusUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_SCHEDULE_STATUS,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response.status, err.message, err))
      })
  }
}

export const createSchedule = (body) => {
  return async (dispatch) => {
    return api
      .post(schedulesUrl, body)
      .then((res) => {
        dispatch({
          type: CREATE_SCHEDULE,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_CREATE_SCHEDULE_WAS_CREATED,
          })
        }, 500)
      })
      .catch((err) => Promise.reject(err))
  }
}

export const updateSchedule = (id, body) => {
  return async (dispatch) => {
    api
      .put(schedulesUrl + '/' + id, body)
      .then((res) => {
        dispatch({
          type: UPDATE_SCHEDULE,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_CREATE_SCHEDULE_WAS_UPDATED,
          })
        }, 500)
      })
      .catch((err) => dispatch(setError(err.response?.status, err.message, err)))
  }
}

export const getALlSchedule = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(schedulesUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_ALL_SCHEDULE_WORK,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response.status, err.message, err))
      })
  }
}

export const getCurrentSchedule = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(currentSchedulesUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_CURRENT_SCHEDULE_WORK,
          data: res,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const resetCurrentSchedule = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_CURRENT_SCHEDULE_WORK,
    })
}

export const resetScheduleWork = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_SCHEDULE_WORK,
    })
}

export const getScheduleById = (id, config = {}) => {
  return async (dispatch) =>
    api
      .get(schedulesUrl + '/' + id, { ...config })
      .then((res) =>
        dispatch({
          type: GET_SCHEDULE_WORK_BY_ID,
          data: res,
        }),
      )
      .catch((err) => dispatch(setError(err.response.status, err.message, err)))
}

export const deleteScheduleById = (id, config = {}) => {
  return async (dispatch) => {
    api
      .delete(schedulesUrl + '/' + id, { ...config })
      .then((res) => {
        dispatch({
          type: DELETE_SCHEDULE,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_DELETE_SCHEDULE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response.status, err.message, err))
      })
  }
}

export const getAllDepartment = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(departmentUrl, {
        params: params,
        ...config,
      })
      .then((response) =>
        dispatch({
          type: GET_ALL_DEPARTMENT,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const getDepartmentById = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(departmentUrl + '/' + params.id, {
        ...config,
      })
      .then((response) =>
        dispatch({
          type: GET_DEPARTMENT_BY_ID,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const createDepartmentBind = (body) => {
  return async (dispatch) => {
    return api
      .post(departmentUrl, body)
      .then((response) =>
        dispatch({
          type: CREATE_DEPARTMENT_BIND,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const updateDepartmentBind = (id, data) => {
  return async (dispatch) => {
    return api
      .put(departmentUrl + '/' + id, data)
      .then((response) =>
        dispatch({
          type: UPDATE_DEPARTMENT_BIND,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}
export const deleteDepartmentBind = (id) => {
  return async (dispatch) => {
    return api
      .delete(departmentUrl + '/' + id)
      .then((response) =>
        dispatch({
          type: DELETE_DEPARTMENT_BIND,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const getPrintModalData = (id, config = {}) => {
  return async (dispatch) => {
    return api
      .get(schedulesUrl + '/' + id + '/print_settings', { ...config })
      .then((response) =>
        dispatch({
          type: GET_PRINT_MODAL_DATA,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const setPrintModalData = (id, body = {}, config = {}) => {
  return async (dispatch) => {
    return api
      .post(schedulesUrl + '/' + id + '/print_settings', body, { ...config })
      .then((response) =>
        dispatch({
          type: SET_PRINT_MODAL_DATA,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const resetPrintModalData = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_PRINT_MODAL_DATA,
    })
}

export const setScheduleDate = ({ date_from, date_to }) => {
  return (dispatch) =>
    dispatch({
      type: SET_SCHEDULE_DATE,
      date_from,
      date_to,
    })
}

export const resetLessonStudyGroups = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_LESSON_STUDY_GROUPS,
    })
}

export const setFirstUnreadLesson = (filter = {}, config = {}) => {
  return async (dispatch) => {
    return api
      .get(lessonUrl + '/first_unread', { ...config })
      .then((response) =>
        dispatch({
          type: SET_FIRST_UNREAD_LESSON,
          data: response,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response.status, err.message, err))
          return Promise.reject(err)
        }
      })
  }
}

export const resetFirstUnreadLesson = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_FIRST_UNREAD_LESSON,
    })
}

export const setPrintData = (data = {}) => {
  return (dispatch) => dispatch({ type: SET_PRINT_DATA, data: data })
}
export const resetPrintData = (data) => {
  return (dispatch) => dispatch({ type: RESET_PRINT_DATA })
}
export const resetLessonStore = (data) => {
  return (dispatch) => dispatch({ type: CLEAR_LESSONS_STORE })
}
export const resetCreatedLessonId = () => {
  return (dispatch) => dispatch({ type: RESET_CREATED_LESSON_ID })
}
